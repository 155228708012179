export default {
    1: {
        id: "01",
        title: "map-2",
        desc: "map-desc-2",
        color: "#FFCB2A",
        icon: "2.png",
    },
    2: {
        id: "02",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    3: {
        id: "03",
        title: "map-3",
        desc: "map-desc-2",
        color: "#FFCB2A",
        icon: "2.png",
    },
    4: {
        id: "04",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    5: {
        id: "05",
        title: "map-4",
        desc: "map-desc-3",
        color: "#B87461",
        icon: "3.png",
    },
    6: {
        id: "06",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    7: {
        id: "07",
        title: "map-5",
        desc: "map-desc-2",
        color: "#FFCB2A",
        icon: "2.png",
    },
    8: {
        id: "08",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    9: {
        id: "09",
        title: "map-6",
        desc: "map-desc-4",
        color: "#8847AF",
        icon: "4.png",
    },
    10: {
        id: "10",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    11: {
        id: "11",
        title: "map-7",
        desc: "map-desc-5",
        color: "#9DD769",
        icon: "5.png",
    },
    12: {
        id: "12",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    13: {
        id: "13",
        title: "map-8",
        desc: "map-desc-6",
        color: "#FE39D8",
        icon: "6.png",
    },
    14: {
        id: "14",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    15: {
        id: "15",
        title: "map-9",
        desc: "map-desc-3",
        color: "#B87461",
        icon: "3.png",
    },
    16: {
        id: "16",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    17: {
        id: "17",
        title: "map-10",
        desc: "map-desc-7",
        color: "#0083C9",
        icon: "7.png",
    },
    18: {
        id: "18",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    19: {
        id: "19",
        title: "map-11",
        desc: "map-desc-8",
        color: "#00BA66",
        icon: "8.png",
    },
    20: {
        id: "20",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    21: {
        id: "21",
        title: "map-12",
        desc: "map-desc-7",
        color: "#0083C9",
        icon: "7.png",
    },
    22: {
        id: "22",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    23: {
        id: "23",
        title: "map-13",
        desc: "map-desc-9",
        color: "#FF3016",
        icon: "9.png",
    },
    24: {
        id: "24",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    25: {
        id: "25",
        title: "map-14",
        desc: "map-desc-2",
        color: "#FFCB2A",
        icon: "2.png",
    },
    26: {
        id: "26",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    27: {
        id: "27",
        title: "map-15",
        desc: "map-desc-3",
        color: "#B87461",
        icon: "3.png",
    },
    28: {
        id: "28",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    29: {
        id: "29",
        title: "map-16",
        desc: "map-desc-10",
        color: "#01BCF1",
        icon: "10.png",
    },
    30: {
        id: "30",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    31: {
        id: "31",
        title: "map-17",
        desc: "map-desc-2",
        color: "#FFCB2A",
        icon: "2.png",
    },
    32: {
        id: "32",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    33: {
        id: "33",
        title: "map-18",
        desc: "map-desc-10",
        color: "#01BCF1",
        icon: "10.png",
    },
    34: {
        id: "34",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    35: {
        id: "35",
        title: "map-19",
        desc: "map-desc-3",
        color: "#B87461",
        icon: "3.png",
    },
    36: {
        id: "36",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    37: {
        id: "37",
        title: "map-20",
        desc: "map-desc-10",
        color: "#01BCF1",
        icon: "10.png",
    },
    38: {
        id: "38",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    39: {
        id: "39",
        title: "map-21",
        desc: "map-desc-6",
        color: "#FE39D8",
        icon: "6.png",
    },
    40: {
        id: "40",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    41: {
        id: "41",
        title: "map-22",
        desc: "map-desc-9",
        color: "#FF3016",
        icon: "9.png",
    },
    42: {
        id: "42",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    43: {
        id: "43",
        title: "map-23",
        desc: "map-desc-2",
        color: "#FFCB2A",
        icon: "2.png",
    },
    44: {
        id: "44",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    45: {
        id: "45",
        title: "map-24",
        desc: "map-desc-8",
        color: "#00BA66",
        icon: "8.png",
    },
    46: {
        id: "46",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    47: {
        id: "47",
        title: "map-25",
        desc: "map-desc-11",
        color: "#FFFFFF",
        icon: "11.png",
    },
    48: {
        id: "48",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
    49: {
        id: "49",
        title: "map-26",
        desc: "map-desc-9",
        color: "#FF3016",
        icon: "9.png",
    },
    50: {
        id: "50",
        title: "map-1",
        desc: "map-desc-1",
        color: "#FFFE34",
        icon: "1.png",
    },
};
