const services = [
    {
        id: 1,
        title: "service",
        description: "service-1",
        subServices: [
            {
                id: 1,
                title: "sub-service-1-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-1-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-1-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-1-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-1-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-1-6", 
                description: ""
            },
            {
                id: 7,
                title: "sub-service-1-7", 
                description: ""
            },
            {
                id: 8,
                title: "sub-service-1-8",
                description: ""
            },
            {
                id: 9,
                title: "sub-service-1-9", 
                description: ""
            },
            {
                id: 10,
                title: "sub-service-1-10",
                description: ""
            },
            {
                id: 11,
                title: "sub-service-1-11",
                description: ""
            },
            {
                id: 12,
                title: "sub-service-1-12",
                description: ""
            },
        ],
    },
    {
        id: 2,
        title: "service",
        description: "service-2",
        subServices: [
            {
                id: 1,
                title: "sub-service-2-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-2-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-2-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-2-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-2-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-2-6",
                description: ""
            },
            {
                id: 7,
                title: "sub-service-2-7",
                description: ""
            },
            {
                id: 8,
                title: "sub-service-2-8",
                description: ""
            },
            {
                id: 9,
                title: "sub-service-2-9",
                description: ""
            },
            {
                id: 10,
                title: "sub-service-2-10",
                description: ""
            },
            {
                id: 11,
                title: "sub-service-2-11",
                description: ""
            },
            {
                id: 12,
                title: "sub-service-2-12",
                description: ""
            },
            {
                id: 13,
                title: "sub-service-2-13",
                description: ""
            },
            {
                id: 14,
                title: "sub-service-2-14",
                description: ""
            },
            {
                id: 15,
                title: "sub-service-2-15",
                description: ""
            },
            {
                id: 16,
                title: "sub-service-2-16",
                description: ""
            },
            {
                id: 17,
                title: "sub-service-2-17",
                description: ""
            },
            {
                id: 18,
                title: "sub-service-2-18",
                description: ""
            },
            {
                id: 19,
                title: "sub-service-2-19",
                description: ""
            },
            {
                id: 20,
                title: "sub-service-2-20",
                description: ""
            },
            {
                id: 21,
                title: "sub-service-2-21",
                description: ""
            },
            {
                id: 22,
                title: "sub-service-2-22",
                description: ""
            },
        ],
    },
    {
        id: 3,
        title: "service",
        description: "service-3", 
        subServices: [
            {
                id: 1,
                title: "sub-service-3-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-3-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-3-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-3-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-3-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-3-6",
                description: ""
            },
            {
                id: 7,
                title: "sub-service-3-7",
                description: ""
            },
            {
                id: 8,
                title: "sub-service-3-8",
                description: ""
            },
            {
                id: 9,
                title: "sub-service-3-9",
                description: ""
            },
            {
                id: 10,
                title: "sub-service-3-10",
                description: ""
            },
            {
                id: 11,
                title: "sub-service-3-11",
                description: ""
            },
            {
                id: 12,
                title: "sub-service-3-12",
                description: ""
            },
            {
                id: 13,
                title: "sub-service-3-13",
                description: ""
            },
            {
                id: 14,
                title: "sub-service-3-14",
                description: ""
            },
            {
                id: 15,
                title: "sub-service-3-15",
                description: ""
            },
            {
                id: 16,
                title: "sub-service-3-16",
                description: ""
            },
            {
                id: 17,
                title: "sub-service-3-17",
                description: ""
            },
            {
                id: 18,
                title: "sub-service-3-18",
                description: ""
            },
            {
                id: 19,
                title: "sub-service-3-19",
                description: ""
            },
            {
                id: 20,
                title: "sub-service-3-20",
                description: ""
            },
            {
                id: 21,
                title: "sub-service-3-21",
                description: ""
            },
            {
                id: 22,
                title: "sub-service-3-22",
                description: ""
            },
            {
                id: 23,
                title: "sub-service-3-23",
                description: ""
            },
            {
                id: 24,
                title: "sub-service-3-24",
                description: ""
            },
            {
                id: 25,
                title: "sub-service-3-25",
                description: ""
            },
            {
                id: 26,
                title: "sub-service-3-26",
                description: ""
            },
            {
                id: 27,
                title: "sub-service-3-27",
                description: ""
            },
            {
                id: 28,
                title: "sub-service-3-28",
                description: ""
            },
            {
                id: 29,
                title: "sub-service-3-29",
                description: ""
            },
            {
                id: 30,
                title: "sub-service-3-30",
                description: ""
            },
            {
                id: 31,
                title: "sub-service-3-31",
                description: ""
            },
            {
                id: 32,
                title: "sub-service-3-32",
                description: ""
            },
        ],
    },
    {
        id: 4,
        title: "service",
        description: "service-4",
        subServices: [
            {
                id: 1,
                title: "sub-service-4-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-4-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-4-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-4-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-4-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-4-6",
                description: ""
            },
            {
                id: 7,
                title: "sub-service-4-7",
                description: ""
            },
            {
                id: 8,
                title: "sub-service-4-8",
                description: ""
            },
            {
                id: 9,
                title: "sub-service-4-9",
                description: ""
            },
        ],
    },
    {
        id: 5,
        title: "service",
        description: "service-5",
        subServices: [
            {
                id: 1,
                title: "sub-service-5-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-5-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-5-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-5-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-5-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-5-6",
                description: ""
            },
        ],
    },
    {
        id: 6,
        title: "service",
        description: "service-6",
        subServices: [
            {
                id: 1,
                title: "sub-service-6-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-6-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-6-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-6-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-6-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-6-6",
                description: ""
            },
            {
                id: 7,
                title: "sub-service-6-7",
                description: ""
            },
            {
                id: 8,
                title: "sub-service-6-8",
                description: ""
            },
            {
                id: 9,
                title: "sub-service-6-9",
                description: ""
            },
        ],
    },
    {
        id: 7,
        title: "service",
        description: "service-7",
        subServices: [
            {
                id: 1,
                title: "sub-service-7-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-7-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-7-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-7-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-7-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-7-6", 
                description: ""
            },
            {
                id: 7,
                title: "sub-service-7-7", 
                description: ""
            },
            {
                id: 8,
                title: "sub-service-7-8",
                description: ""
            },
            {
                id: 9,
                title: "sub-service-7-9", 
                description: ""
            },
            {
                id: 10,
                title: "sub-service-7-10",
                description: ""
            },
            // {
            //     id: 11,
            //     title: "sub-service-7-11",
            //     description: ""
            // },
            // {
            //     id: 12,
            //     title: "sub-service-7-12",
            //     description: ""
            // },
        ],
    },
    {
        id: 8,
        title: "service",
        description: "service-8", 
        subServices: [
            {
                id: 1,
                title: "sub-service-8-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-8-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-8-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-8-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-8-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-8-6", 
                description: ""
            },
            {
                id: 7,
                title: "sub-service-8-7", 
                description: ""
            },
            {
                id: 8,
                title: "sub-service-8-8",
                description: ""
            },
        ],
    },
    {
        id: 9,
        title: "service",
        description: "service-9",
        subServices: [
            {
                id: 1,
                title: "sub-service-9-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-9-2",
                description: ""
            },
            {
                id: 3,
                title: "sub-service-9-3",
                description: ""
            },
            {
                id: 4,
                title: "sub-service-9-4",
                description: ""
            },
            {
                id: 5,
                title: "sub-service-9-5",
                description: ""
            },
            {
                id: 6,
                title: "sub-service-9-6", 
                description: ""
            },
        ],
    },
    {
        id: 10,
        title: "service",
        description: "service-10",
        subServices: [
            {
                id: 1,
                title: "sub-service-10-1",
                description: ""
            },
            {
                id: 2,
                title: "sub-service-10-2",
                description: ""
            },
        ],
    },
];

const subServices = [

];

export { services, subServices };
